import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import LicenceDetailsBaseComponent, {
  LicenceDetailsData,
} from 'components/FormPage/licenceDetails';
import { DriverType } from 'types/forms';

type LicenceDetailsProps = {
  data: {
    csAddDriverLicenceDetails: LicenceDetailsData;
  };
  location: Location;
};

const LicenceDetailsPage: React.FC<LicenceDetailsProps> = ({ data, location }) => (
  <LicenceDetailsBaseComponent
    data={data.csAddDriverLicenceDetails}
    location={location}
    driverType={DriverType.TEMPORARY}
  />
);

export default LicenceDetailsPage;

export const query = graphql`
  query {
    csAddDriverLicenceDetails {
      title
      heading
    }
  }
`;
